import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import echoMindLogo from "../images/Screenshot 2023-08-16 172928.png";
import { BASE_DOMAIN } from "./constants";
import { Calendar } from "primereact/calendar";
import { Sidebar } from "primereact/sidebar";
import "../style.css";
import { Checkbox } from "primereact/checkbox";
import { TriStateCheckbox } from "primereact/tristatecheckbox";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";

export default function Table() {
  const [userTasks, setUserTasks] = useState([]);
  const [selectedTasks, setSelectedTasks] = useState(null);
  const [globalFilter, setGlobalFilter] = useState(null);
  const [audioPlayingStatus, setAudioPlayingStatus] = useState({});
  const [newTask, setNewTask] = useState("");
  const [isAddNFDDialogVisible, setIsAddNFDDialogVisible] = useState(false);
  const [selectedNFDDateTime, setSelectedNFDDateTime] = useState(null);
  const [selectedTaskId, setSelectedTaskId] = useState(null);
  const [taskNotes, setTaskNotes] = useState("");

  const [isAddDialogVisible, setIsAddDialogVisible] = useState(false);
  const [isEditDialogVisible, setIsEditDialogVisible] = useState(false);

  const [editTaskData, setEditTaskData] = useState({
    event_id: null,
    task: "",
    startTime: "",
    endTime: "",
  });
  const [updateMessage, setUpdateMessage] = useState("");
  const [deleteTaskData, setDeleteTaskData] = useState(null);
  const [isDeleteConfirmationVisible, setIsDeleteConfirmationVisible] =
    useState(false);
  const [taskHistory, setTaskHistory] = useState([]);

  const [isUserDialogVisible, setIsUserDialogVisible] = useState(false);
  const [selectedTaskDetails, setSelectedTaskDetails] = useState(null);
  const [sidebarVisible, setSidebarVisible] = useState(false);
  const audioElement = useRef(null);
  const [taskNumber, setTaskNumber] = useState(1); // Initialize task number
  const toastRef = useRef(null);
  const [selectedStartDate, setSelectedStartDate] = useState(null); // New state for start date
  const [selectedEndDate, setSelectedEndDate] = useState(null);
  const editTask = (rowData) => {
    setEditTaskData({
      task_reference: rowData.task_reference,
      task: rowData.task_name,
      first_followup_datetime: rowData.first_followup_datetime,
      next_followup_datetime: rowData.next_followup_datetime,
    });
  
    setSelectedStartDate(new Date(rowData.first_followup_datetime));
    setSelectedEndDate(new Date(rowData.next_followup_datetime));
  
    setIsEditDialogVisible(true);
  };
  const toggleAudioPlayback = async (event_id, audio_url) => {
    try {
      if (audioPlayingStatus[event_id]) {
        // If audio is currently playing, pause it
        audioElement.current.pause();
      } else {
        // If audio is not playing, load and play it
        audioElement.current.src = `${BASE_DOMAIN}/${audio_url}`;
        await audioElement.current.play();
      }

      // Toggle the playback status
      setAudioPlayingStatus((prevState) => ({
        ...prevState,
        [event_id]: !prevState[event_id],
      }));
    } catch (error) {
      console.error("Error playing audio:", error);
    }
  };

  const handleEditTask = async () => {
    try {
      const token = localStorage.getItem('uuid');
    const requestData = {
      unique_id: localStorage.getItem('uuid'),
      event_id: editTaskData.task_reference, // Use task_reference as event_id
      task: editTaskData.task, // Use task as task_name
      task_start_time: selectedStartDate.toISOString(), // Convert to ISOString
      task_end_time: selectedEndDate.toISOString(), // Convert to ISOString
    };

    const response = await fetch(`${BASE_DOMAIN}/up-event`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestData),
    });
      const responseData = await response.json();

      if (response.ok) {
        setUpdateMessage(responseData.message);
        window.location.reload();
        // Update userTasks with the edited task data
        const updatedTasks = userTasks.map((task) => {
          if (task.event_id === editTaskData.event_id) {
            return {
              ...task,
              task: editTaskData.task, // Update only the task name
              task_start_time: editTaskData.startTime,
              task_end_time: editTaskData.endTime,
            };
          }
          return task;
        });
        toastRef.current.show({
          severity: 'success',
          summary: 'success',
          detail: 'Task updated successfully',
        });
        setUserTasks(updatedTasks);
      } else {
        console.error('Failed to update task');
      }
    } catch (error) {
      console.error('Error updating task:', error);
    }

    setIsEditDialogVisible(false); // Close the dialog
  };
  const toast = useRef(null);
  const dt = useRef(null);

  const unique_id = localStorage.getItem("unique_id");

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const userUUID = urlParams.get("user");
    localStorage.setItem("uuid", userUUID);

    if (userUUID) {
      // Fetch data using the userUUID
      const fetchData = async () => {
        try {
          const response = await fetch(`${BASE_DOMAIN}/user-tasks`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ unique_id: userUUID }),
          });

          const responseData = await response.json();

          if (response.ok) {
            const tasksWithNumbers = responseData.userTasks.map(
              (task, index) => ({
                ...task,
                taskNumber: index + 1,
              })
            );
            const incompleteTasks = tasksWithNumbers.filter(
              (task) => !task.completed
            );

            setUserTasks(incompleteTasks);
          } else {
            console.error("Failed to fetch user tasks");
          }
        } catch (error) {
          console.error("Error fetching user tasks:", error);
        }
      };
      fetchData();
    }
  }, []);

  const formatDateTime = (dateTimeStr) => {
    if (!dateTimeStr) return ""; // Handle empty date
    const [datePart, timePart] = dateTimeStr.split("T"); // Split date and time
    const [year, month, day] = datePart.split("-").map(Number); // Parse date parts
    const [hour, minute] = timePart.split(":").map(Number); // Parse time parts
    const date = new Date(year, month - 1, day, hour, minute); // Create a Date object

    const timeFormat = "hh:mma"; // Format for time (12-hour clock with AM/PM)
    const dateFormat = "dd-MM-yyyy"; // Format for date
    return `${format(date, timeFormat)}-${format(date, dateFormat)}`;
  };

  const addNewTask = () => {
    setIsAddDialogVisible(true);
  };

  const handleAddTask = async () => {
    if (newTask.trim() !== "") {
      try {
        const requestData = {
          unique_id: localStorage.getItem("uuid"),
          text: newTask,
        };

        const response = await fetch(`${BASE_DOMAIN}/add-event`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestData),
        });

        const responseData = await response.json();

        if (response.ok) {
          setUpdateMessage(responseData.message);
          console.log(responseData.message);

          // Add the newly created task to the userTasks array
          const newTaskData = {
            event_id: responseData.eventData.event_id, // Use the received ID
            task: responseData.eventData.task,
            task_start_time: responseData.eventData.task_start_time,
            task_end_time: responseData.eventData.task_end_time,
            taskNumber: userTasks.length + 1, // Increment task number
          };

          const updatedTasks = [...userTasks, newTaskData];
          setTaskNumber(taskNumber + 1); // Increment task number
          setUserTasks(updatedTasks);

          // Reset input and close the dialog
          setIsAddDialogVisible(false);
          setNewTask("");
          toastRef.current.show({
            severity: "success",
            summary: "success",
            detail: "Task Added successfully",
          });
        } else {
          console.error("Failed to add task");
          toastRef.current.show({
            severity: "error",
            summary: "Error",
            detail: "Failed to add task",
          });
        }
      } catch (error) {
        console.error("Error adding task:", error);
      }
    }
  };

  const editDialogFooter = (
    <div className="d-flex justify-content-center">
      <Button
        label="Cancel"
        icon="pi pi-times"
        className="p-button-text fs-3"
        onClick={() => setIsEditDialogVisible(false)}
      />
      <Button
        label="Update"
        icon="pi pi-check"
        className="p-button-text fs-3"
        onClick={handleEditTask}
      />
    </div>
  );
  const addDialogFooter = (
    <div>
      <Button
        label="Cancel"
        icon="pi pi-times"
        className="p-button-text fs-3"
        onClick={() => setIsAddDialogVisible(false)}
      />
      <Button
        label="Add"
        icon="pi pi-check"
        className="p-button-text fs-3"
        onClick={handleAddTask}
      />
    </div>
  );

  const confirmDeleteTask = (rowData) => {
    setDeleteTaskData(rowData);
    setIsDeleteConfirmationVisible(true);
  };

  // Function to send a DELETE request and update the table
  const handleDeleteTask = async () => {
    try {
      const token = localStorage.getItem("uuid");
      const requestData = {
        unique_id: localStorage.getItem("uuid"),
        event_id: deleteTaskData.task_reference,
      };

      const response = await fetch(`${BASE_DOMAIN}/delete-event`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      });

      const responseData = await response.json();

      if (response.ok) {
        setUpdateMessage(responseData.message);
        // Remove the deleted task from the userTasks state
        const updatedTasks = userTasks.filter(
          (task) => task.event_id !== deleteTaskData.event_id
        );
        toastRef.current.show({
          severity: "success",
          summary: "success",
          detail: "successfully deleted",
        });
        window.location.reload();
        setUserTasks(updatedTasks);
      } else {
        console.error("Failed to delete task");
      }
    } catch (error) {
      console.error("Error deleting task:", error);
    }

    setIsDeleteConfirmationVisible(false); // Close the delete confirmation dialog
  };

  const deleteConfirmationFooter = (
    <div className="d-flex justify-content-center">
      <Button
        label="No"
        icon="pi pi-times"
        className="p-button-text fs-3"
        onClick={() => setIsDeleteConfirmationVisible(false)}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        className="p-button-text fs-3"
        onClick={handleDeleteTask}
      />
    </div>
  );

  const navigate = useNavigate(); // Add this line to get the navigation function

  const handleLogout = () => {
    // Remove the UUID from local storage
    localStorage.removeItem("token");

    // Navigate to the login page
    navigate("/login");
  };

  const leftToolbarTemplate = () => {
    return (
      <div className="flex flex-wrap d-flex justify-content-center align-items-center mx-2">
        <a href="/">
          <img
            alt="logo"
            style={{ height: "7rem" }}
            src={echoMindLogo}
            className="mr-2"
          ></img>
        </a>

        <h4
          className="my-4 fs-1 fw-bold"
          style={{ position: "relative", top: "2px" }}
        >
          Manage Tasks
        </h4>
        <Button
          icon="pi pi-bars"
          onClick={() => setSidebarVisible(true)}
          className="p-ml-4 sidebar ml-4"
          style={{ marginLeft: "1rem" }}
        />
        <Sidebar
          visible={sidebarVisible}
          onHide={() => setSidebarVisible(false)}
          position="left"
        >
          <div className="p-d-flex p-flex-column p-m-4">
            <div>
              <Button
                label="All tasks"
                className="p-button-text"
                style={{
                  width: "100%",
                  marginTop: "1.5rem",
                  fontSize: "1.6rem",
                  color: "black",
                }}
                onClick={handlePendingTask}
              />
            </div>
          </div>
        </Sidebar>
      </div>
    );
  };

  const handlePendingTask = () => {
    navigate("/AllTask");
  };

  const handleCheckboxChange = async (rowData, e) => {
    try {
      const requestData = {
        unique_id: localStorage.getItem("uuid"),
        taskMainId: rowData.id, // Assuming id is the identifier for the task
        completed: e.checked,
      };

      const response = await fetch(`${BASE_DOMAIN}/task-complete`, {
        method: "POST", // Assuming you're using POST to mark tasks as completed
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      });

      const responseData = await response.json();

      if (response.ok) {
        const updatedTasks = userTasks.map((task) => {
          if (task.id === rowData.id) {
            return {
              ...task,
              finished: false, // Assuming finished field is used to display checkbox
              completed: true, // Assuming completed field is used to display "Completed" text
            };
          }
          return task;
        });
        setUserTasks(updatedTasks);
        window.location.reload();
        console.log(updatedTasks);
      } else {
        console.error("Failed to mark task as completed");
      }
    } catch (error) {
      console.error("Error marking task as completed:", error);
    }
  };
  const rightToolbarTemplate = () => {
    return (
      <div className="d-flex align-items-center">
        <span className="p-input-icon-left mx-2 d-flex flex-wrap">
          <i className="pi pi-search px-3" />
          <InputText
            className="fs-5 px-5 m-1 p-inputtext-lg rounded-pill"
            type="search"
            onInput={(e) => setGlobalFilter(e.target.value)}
            placeholder="Search..."
          />
        </span>
        <div className="flex align-items-center justify-content-end">
          <Button
            type="button"
            icon="pi pi-plus fs-3"
            rounded
            className="m-1"
            style={{ height: "4rem", width: "4rem" }}
            onClick={() => addNewTask()}
          />
        </div>
      </div>
    );
  };
  const completedCheckboxTemplate = (rowData) => {
    if (rowData.completed) {
      return <div>Completed</div>;
    } else {
      return (
        <Checkbox
          checked={rowData.finished}
          onChange={(e) => handleCheckboxChange(rowData, e)}
        />
      );
    }
  };
  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <Button
          icon="pi pi-pencil fs-4"
          rounded
          outlined
          className="mr-2 my-2"
          style={{ height: "3rem", width: "3rem" }}
          onClick={() => editTask(rowData)}
        />
        <Button
          icon="pi pi-trash fs-4"
          rounded
          outlined
          severity="danger"
          style={{ height: "3rem", width: "3rem" }}
          className="m-2"
          onClick={() => confirmDeleteTask(rowData)}
        />
        <Button
          icon="pi pi-user fs-4"
          rounded
          outlined
          severity="success"
          style={{ height: "3rem", width: "3rem" }}
          className="m-2"
          onClick={() => openUserDialog(rowData)}
        />
      </React.Fragment>
    );
  };

  const playPauseButtonTemplate = (rowData) => {
    const isPlaying = audioPlayingStatus[rowData.event_id];

    return (
      <Button
        icon={isPlaying ? "pi pi-pause" : "pi pi-play"}
        rounded
        outlined
        style={{ height: "3rem", width: "3rem" }}
        className="m-2"
        onClick={() => toggleAudioPlayback(rowData.event_id, rowData.audio_url)}
      />
    );
  };

  const openUserDialog = async (rowData) => {
    setSelectedTaskDetails(rowData);
    setIsUserDialogVisible(true);
    const history = await fetchTaskHistory(rowData.id);
    setTaskHistory(history);
  };
 
  const userDialogFooter = (
    <div className="d-flex justify-content-center">
      <Button
        label="Add NFD"
        icon="pi pi-plus"
        severity="success"
        className="p-button-text fs-3"
        onClick={() => setIsAddNFDDialogVisible(true)}
      />
      <Button
        label="Close"
        icon="pi pi-times"
        className="p-button-text fs-3"
        onClick={() => setIsUserDialogVisible(false)}
      />
    </div>
  );
  //

  const fetchTaskHistory = async (taskId) => {
    try {
      const response = await fetch(`${BASE_DOMAIN}/nfd?id=${taskId}`);
      const data = await response.json();

      if (response.ok) {
        return data.taskAudits;
      } else {
        console.error("Failed to fetch task history");
        return [];
      }
    } catch (error) {
      console.error("Error fetching task history:", error);
      return [];
    }
  };

  const handleAddNFD = async () => {
    if (selectedNFDDateTime && selectedTaskDetails) {
      const taskId = selectedTaskDetails.id; // Accessing the task ID from selectedTaskDetails

      selectedNFDDateTime.setSeconds(0);
      const requestData = {
        nextFollowupDatetime: selectedNFDDateTime.toISOString(),
        taskMainId: taskId,
        task_note: taskNotes,
      };

      try {
        const response = await fetch(`${BASE_DOMAIN}/nfd`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestData),
        });

        if (response.ok) {
          setIsAddNFDDialogVisible(false);
          // Handle success
          const updatedTaskHistory = await fetchTaskHistory(taskId);
          setTaskHistory(updatedTaskHistory);
          window.location.reload();
          console.log("NFD added successfully");
        } else {
          // Handle error
          console.error("Failed to add NFD");
        }
      } catch (error) {
        console.error("Error adding NFD:", error);
      }
    }
  };

  const addNFDDialogFooter = (
    <div className="d-flex justify-content-center">
      <Button
        label="Cancel"
        icon="pi pi-times"
        className="p-button-text fs-3"
        onClick={() => setIsAddNFDDialogVisible(false)}
      />
      <Button
        label="Add"
        icon="pi pi-check"
        className="p-button-text fs-3"
        onClick={handleAddNFD}
      />
    </div>
  );

  const formatTime = (dateTimeStr) => {
    if (!dateTimeStr) return ""; // Handle empty date
    const date = new Date(dateTimeStr);
  
    const timeFormat = "hh:mma"; // Format for time (12-hour clock with AM/PM)
    const dateFormat = "dd-MM-yyyy"; // Format for date
  
    const formattedTime = format(date, timeFormat);
    const formattedDate = format(date, dateFormat);
  
    return `${formattedTime}-${formattedDate}`;
  };
  
  useEffect(() => {
    const fetchLastNFDDateTime = async () => {
      if (selectedTaskDetails) {
        const taskId = selectedTaskDetails.id;
        const history = await fetchTaskHistory(taskId);
  
        if (history.length > 0) {
          const lastNFDDateTime = new Date(history[history.length - 1].next_followup_datetime);
          setSelectedNFDDateTime(lastNFDDateTime);
        }
      }
    };
  
    fetchLastNFDDateTime();
  }, [selectedTaskDetails]);

  return (
    <div className="m-3" style={{ userSelect: "none" }}>
      <Toast ref={toast} />
      <div className="card border-0">
        <Toolbar
          className="fs-4 mb-4 d-flex justify-content-sm-center justify-content-center justify-content-md-between justify-content-lg-between flex-lg-row flex-sm-column flex-md-row"
          left={leftToolbarTemplate}
          right={rightToolbarTemplate}
        ></Toolbar>

        <DataTable
          className="fs-3"
          ref={dt}
          value={userTasks}
          selection={selectedTasks}
          onSelectionChange={(e) => setSelectedTasks(e.value)}
          paginator
          rows={10}
          rowsPerPageOptions={[5, 10, 25]}
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
          globalFilter={globalFilter}
        >
          <Column field="id" sortable header="Id" style={{ width: "5%" }} />
          <Column field="task_name" header="Task" style={{ width: "15%" }} />
          {/* <Column field="start_dateTime" header="Start Time" style={{ width: '15%' }} body={(rowData) => formatDateTime(rowData.task_start_time)} /> */}
          <Column
            field="c"
            header="Start Time"
            style={{ width: "15%" }}
            body={(rowData) => formatDateTime(rowData.first_followup_datetime)}
          />
          <Column
            field="next_followup_datetime"
            sortable
            header="Next Followup"
            style={{ width: "15%" }}
            body={(rowData) => formatDateTime(rowData.next_followup_datetime)}
          />
          <Column field="notes" header="Notes" style={{ width: "10%" }} />
          <Column
            field="Actions"
            header="Actions"
            body={actionBodyTemplate}
            exportable={false}
            style={{ width: "10%" }}
          />
          <Column
            field="audio"
            header="Audio"
            body={playPauseButtonTemplate}
            style={{ width: "10%" }}
          />
          <Column
            field="completed"
            header="Finished"
            body={completedCheckboxTemplate}
            style={{ width: "10%" }}
          />
        </DataTable>
        <audio ref={audioElement} controls style={{ display: "none" }} />
        <div className="card flex justify-content-center">
          <Toast ref={toastRef} />
          {/* <Button onClick={show} label="Show" /> */}
        </div>
      </div>

      <Dialog
        visible={isEditDialogVisible}
        style={{ width: "30%", fontSize: "1.5rem", fontWeight: "600" }}
        onHide={() => setIsEditDialogVisible(false)}
        footer={editDialogFooter}
        header="Edit Task"
      >
        <div className="p-fluid">
          <div className="p-field">
            <label htmlFor="editTask">Edit Task</label>
            <InputText
  id="editTask"
  className="fs-4"
  value={editTaskData.task} // Bind to editTaskData.task
  onChange={(e) =>
    setEditTaskData({ ...editTaskData, task: e.target.value })
  }
  placeholder="Edit the task!"
/>
          </div>
          <div className="p-field">
            <label htmlFor="editStartTime">Start Time</label>
            <Calendar
              id="editStartTime"
              className="text-3xl"
              value={selectedStartDate}
              onChange={(e) => setSelectedStartDate(e.value)}
              showTime
            />
          </div>
          <div className="p-field">
            <label htmlFor="editEndTime">End Time</label>
            <Calendar
              id="editEndTime"
              className="text-4xl"
              value={selectedEndDate}
              onChange={(e) => setSelectedEndDate(e.value)}
              showTime
            />
          </div>
        </div>
      </Dialog>

      {updateMessage && (
        <div className="p-grid">
          <div className="p-col-12">
            <div className="card">
              <div className="p-card-body">
                <h5>{updateMessage}</h5>
              </div>
            </div>
          </div>
        </div>
      )}
      <Dialog
        visible={isUserDialogVisible}
        style={{
          width: "70vw",
          height: "70vh",
          fontSize: "1.3rem",
          fontWeight: "600",
        }}
        onHide={() => setIsUserDialogVisible(false)}
        footer={userDialogFooter}
        header="User Details"
      >
        <div className="p-fluid">
          {selectedTaskDetails && (
            <div>
              <div>ID: {selectedTaskDetails.id}</div>
              <div>Task: {selectedTaskDetails.task_name}</div>
              <div>
                Start Time:{" "}
                {formatDateTime(selectedTaskDetails.first_followup_datetime)}
              </div>
              <div>
                Next Followup:{" "}
                {formatDateTime(selectedTaskDetails.next_followup_datetime)}
              </div>
              <div>
                Done/Not Done:{" "}
                {selectedTaskDetails.finished ? "Done" : "Not Done"}
              </div>
              <div className="mt-4">
                <DataTable value={taskHistory}>
                  <Column
                    field="id"
                    header="ID"
                    style={{ fontSize: "1.4rem", fontWeight: "500" }}
                  />
                <Column
  field="next_followup_datetime"
  header="Next Followup"
  style={{ fontSize: "1.4rem", fontWeight: "500" }}
  body={(rowData) => formatTime(rowData.next_followup_datetime)}
/>
                  <Column
                    field="task_note"
                    header="Notes"
                    style={{ fontSize: "1.4rem", fontWeight: "500" }}
                  />
                </DataTable>
              </div>
            </div>
          )}
        </div>
      </Dialog>

      <Dialog
        visible={isAddDialogVisible}
        style={{ width: "40%" }}
        onHide={() => setIsAddDialogVisible(false)}
        header="Add New Task"
        footer={addDialogFooter}
      >
        <div className="p-fluid">
          <div className="p-field">
            {/* <label htmlFor="newTask">New Task</label> */}
            <InputText
              id="newTask"
              className="fs-4"
              value={newTask}
              onChange={(e) => setNewTask(e.target.value)}
              placeholder="Enter a new task"
            />
          </div>
        </div>
      </Dialog>
      <Dialog
        visible={isDeleteConfirmationVisible}
        className="w-50"
        onHide={() => setIsDeleteConfirmationVisible(false)}
        footer={deleteConfirmationFooter}
        header="Confirm Delete"
      >
        <div className="p-fluid">
          <div className="p-field">
            <label className="fs-3">
              Are you sure you want to delete this event?
            </label>
          </div>
        </div>
      </Dialog>
      <Dialog
        visible={isAddNFDDialogVisible}
        style={{ width: "40%" }}
        onHide={() => setIsAddNFDDialogVisible(false)}
        header="Add Next Followup Date"
        footer={addNFDDialogFooter}
      >
        <div className="p-fluid">
          <div className="p-field">
            <label htmlFor="nfdDateTime" style={{ fontSize: "1.3rem" }}>
              Select Date and Time
            </label>
            <Calendar
              id="nfdDateTime"
              className="fs-4"
              value={selectedNFDDateTime}
              onChange={(e) => setSelectedNFDDateTime(e.value)}
              showTime
            />
          </div>
          <div className="p-field">
      <label htmlFor="taskNotes" style={{ fontSize: "1.3rem" }}>
        Task Notes
      </label>
      <InputText
        id="taskNotes"
        className="fs-4"
        value={taskNotes}
        onChange={(e) => setTaskNotes(e.target.value)}
      />
    </div>
        </div>
      </Dialog>
    </div>
  );
}
